<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 신청기간 -->
          <c-datepicker
            :range="true"
            name="period"
            label="신청기간"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="period"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            :plantCd="searchParam.plantCd"
            :isFirstValue="false"
            type="search"
            label="신청부서"
            name="requestDeptCd"
            v-model="searchParam.requestDeptCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field
            label="구매신청자"
            name="requestUserId"
            v-model="searchParam.requestUserId"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="SAS_BUY_STATUS_CD"
            itemText="codeName"
            itemValue="code"
            name="statusCd"
            label="진행상태"
            v-model="searchParam.statusCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="신규 보호구 구매신청 목록"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openRequest" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-gear-buy-request',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'requestDate',
            field: 'requestDate',
            label: '구매신청일',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'requestItems',
            field: 'requestItems',
            label: '구매신청 품목',
            style: 'width:300px',
            align: 'left',
            sortable: true,
            type: 'link'
          },
          {
            name: 'sumPrice',
            field: 'sumPrice',
            label: '총 금액',
            style: 'width:100px',
            type: 'cost',
            align: 'right',
            suffix: '원',
            sortable: true,
          },
          {
            name: 'requestDeptName',
            field: 'requestDeptName',
            label: '신청부서',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'requestUserName',
            field: 'requestUserName',
            label: '구매신청자',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'statusName',
            field: 'statusName',
            label: '진행단계',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        requestDeptCd: '',
        requestUserId: '',
        startYmd: '',
        endYmd: '',
        statusCd: null,
      },
      period: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.sas.buy.list.url;
      this.getList();
    },
    /* eslint-disable no-unused-vars */
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    openRequest() {
      this.linkClick({buyRequestId:''});
    },
    linkClick(row, col, index) {
      this.popupOptions.title = '신규 보호구 구매신청 상세'; // 
      this.popupOptions.param = {
        buyRequestId: row.buyRequestId ? row.buyRequestId : '',
      };
      this.popupOptions.target = () => import(`${'./safetyGearBuyRequestDetail.vue'}`);
      this.popupOptions.visible = true;
      // this.popupOptions.isFull = true;
      this.popupOptions.width = '80%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>